import produce from 'immer';
import {UserReducer} from '../../types';
import {setUser, logoutAction} from '../actions/userActions';

export const initialState: UserReducer = {
  user: undefined,
  token: '',
};

const userReducer = produce((draft: UserReducer, action: any) => {
  switch (action.type) {
    case setUser.toString():
      const user: UserReducer = action.payload;
      draft.user = user?.user;
      draft.token = user?.token;
      break;
    case logoutAction.toString():
      draft.user = initialState.user;
      draft.token = initialState.token;
      break;
  }
}, initialState);

export default userReducer;
