/**
 * smartoffice
 * https://komutteknolojisi.com
 *
 * @author Mehmet Polat Koçak <mehmet@komutteknolojisi.com>
 * @format
 * @flow strict-local
 */

import {ApiResponse} from 'types';

export const baseUrl = 'https://managementapi.smartoffice.com.tr/';
//export const baseUrl = 'http://localhost:25965/';

export const SERVER_DATE_FORMAT = 'LLL';
export const getFormData = (data: Object): FormData => {
  let formData = new FormData();
  Object.keys(data).forEach((key) => {
    const val = (data as any)[key];
    if (Array.isArray(val)) {
      val.forEach((v, i) =>
        Object.keys(v).forEach((k) =>
          formData.append(`${key}[${i}].${k}`, v[k]),
        ),
      );
    } else formData.append(key, val);
  });
  return formData;
};

export const getPreferredLanguage = () => {
  const lang =
    (navigator.languages && navigator.languages[0]) ||
    navigator.language ||
    'en';
  return lang;
};

export const verifyResponse = (response: ApiResponse<any, any>): boolean => {
  switch (response?.ResultStatus) {
    case 0: // NoRecord = 0
    case 1: //NewRecordAdded = 1
    case 2: //RecordUpdated = 2
    case 3: //RecordDeleted = 3
    case 9: //QuerySuccess = 9
    case 17: //SuccesfullyAuthorized = 17
    case 19: //PasswordChanged = 19
      return true;
    case 4: //ErrorOccured = 4
    case 5: //WrongLogin = 5
    case 6: //SystemErrorOccured = 6
    case 7: //SameRecordNotAdded = 7
    case 8: //CitizenUINValidationError = 8
    case 10: //QueryError = 10
    case 11: //Unauthorized = 11
    case 12: //CantDeleteHasSubRows = 12
    case 13: //CantDeleteImage = 13
    case 14: //CantUpdateImage = 14
    case 15: //SessionTimeout = 15
    case 16: //PackageExpired = 16
    default:
      return false;
  }
};
export const createMapOptions = (maps: any) => {
  return {
    styles: CUSTOM_MAP,
    mapTypeId: 'satellite',
  };
};
export const CUSTOM_MAP = [
  {
    elementType: 'geometry',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    elementType: 'labels',
    stylers: [
      {
        visibility: 'on',
      },
    ],
  },
  {
    elementType: 'labels.icon',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#212121',
      },
    ],
  },
  {
    featureType: 'administrative',
    elementType: 'geometry',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'administrative.country',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#9e9e9e',
      },
    ],
  },
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'administrative.locality',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#bdbdbd',
      },
    ],
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#181818',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'poi.park',
    elementType: 'labels.text.stroke',
    stylers: [
      {
        color: '#1b1b1b',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#2c2c2c',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#8a8a8a',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'geometry',
    stylers: [
      {
        color: '#373737',
      },
    ],
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#3c3c3c',
      },
    ],
  },
  {
    featureType: 'road.highway.controlled_access',
    elementType: 'geometry',
    stylers: [
      {
        color: '#4e4e4e',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#616161',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#757575',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry',
    stylers: [
      {
        color: '#000000',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'labels.text.fill',
    stylers: [
      {
        color: '#3d3d3d',
      },
    ],
  },
];
