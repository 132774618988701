import produce from 'immer';
import {StaticReducer} from '../../types';
import * as S from '../actions/staticActions';

export const initialState: StaticReducer = {
  rooms: {Products: [], status: ''},
  contacts: {Contacts: [], status: ''},
};

const userReducer = produce((draft: StaticReducer, action: any) => {
  switch (action.type) {
    case S.setRoomsByAccount.toString():
      const rooms = action.payload;
      draft.rooms = {Products: rooms?.Products, status: rooms?.status};
      break;
    case S.setAllContactByAccount.toString():
      const contacts = action.payload;
      draft.contacts = {Contacts: contacts?.Contacts, status: contacts?.status};
      break;
  }
}, initialState);

export default userReducer;
