import {takeLatest, call, put} from '@redux-saga/core/effects';
import * as P from '../../types/params';
import * as O from '../../types/objects';
import * as S from '../actions/staticActions';
import {ApiCallback, ApiResponse} from '../../types/index';
import {verifyResponse} from '../../utils/Properties';
import {
  BodyType,
  createRequestSaga,
  fetchError,
  fetchStart,
  fetchSuccess,
  showMessage,
} from 'redux/actions';

/** ROOMS **/

function* getRoomsByAccountSaga({
  payload,
}: {
  type: string;
  payload: P.UseAccountIdParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.RoomsObject, null> = yield call(
      createRequestSaga,
      {
        method: 'GET',
        url: 'Door/GetRoomsByAccount',
        body: payload,
      },
    );
    if (verifyResponse(response)) {
      yield put(S.setRoomsByAccount(response.Result as O.RoomsObject));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR GetRoomsByAccount', e);
    yield put(fetchError(e.Message));
  }
}

export interface GetAuthorizedContactsByRoomSagaParams
  extends ApiCallback<O.RoomContactsObject> {
  params: P.GetAuthorizedContactsByRoomParams;
}

function* getAuthorizedContactsByRoomSaga({
  payload,
}: {
  type: string;
  payload: GetAuthorizedContactsByRoomSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.RoomContactsObject, null> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Door/GetAuthorizedContactsByRoom',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(fetchSuccess());
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR GetAuthorizedContactsByRoom', e.Message);
    yield put(fetchError(e.Message));
  }
}

function* getUnauthorizedContactsByRoomSaga({
  payload,
}: {
  type: string;
  payload: GetAuthorizedContactsByRoomSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.RoomContactsObject, null> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Door/GetUnAuthorizedContactsByRoom',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(fetchSuccess());
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR GetUnAuthorizedContactsByRoom', e);
    yield put(fetchError(e.Message));
  }
}

export interface AuthorizeContactOnRoomSagaParams
  extends ApiCallback<{status: string}> {
  params: P.AuthorizeContactOnRoomParams;
}

function* unauthorizeContactOnRoomSaga({
  payload,
}: {
  type: string;
  payload: AuthorizeContactOnRoomSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<{status: string}, null> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Door/UnauthorizeContactOnRoom',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(showMessage(response.Message));
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

function* authorizeContactOnRoomSaga({
  payload,
}: {
  type: string;
  payload: AuthorizeContactOnRoomSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<{status: string}, null> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Door/AuthorizeContactOnRoom',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(showMessage(response.Message));
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR AuthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

/** USERS **/
function* getAllContactByAccountSaga({
  payload,
}: {
  type: string;
  payload: P.UseAccountIdParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.ContactsObject, null> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Door/GetAllContactByAccount',
        body: payload,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      yield put(S.setAllContactByAccount(response.Result));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

export interface GetContactDetailsSagaParams extends ApiCallback<O.Contact2> {
  params: P.ContactDetailParams;
}

function* contactDetailsSaga({
  payload,
}: {
  type: string;
  payload: GetContactDetailsSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.Contact2, null> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Door/GetContactById',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(fetchSuccess());
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

export interface CreateContactSagaParams
  extends ApiCallback<O.RoomContactsObject> {
  params: P.CreateContactParams;
}

function* createContactSaga({
  payload,
}: {
  type: string;
  payload: CreateContactSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.RoomContactsObject, null> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Door/CreateContact',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(showMessage(response.Message));
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

function* updateContactSaga({
  payload,
}: {
  type: string;
  payload: CreateContactSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.RoomContactsObject, null> = yield call(
      createRequestSaga,
      {
        method: 'PUT',
        url: 'Door/UpdateContact',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(showMessage(response.Message));
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

export interface GetRoomsByContactIdSagaParams
  extends ApiCallback<O.ContactDoor[]> {
  params: P.ContactDetailParams;
}

function* getRoomsByContactIdSaga({
  payload,
}: {
  type: string;
  payload: GetRoomsByContactIdSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.ContactDoorObject, null> = yield call(
      createRequestSaga,
      {
        method: 'GET',
        url: 'Door/GetRoomsByContactId',
        body: payload.params,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result.Doors);
      yield put(fetchSuccess());
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

const staticSaga = [
  takeLatest(S.getRoomsByAccount.toString(), getRoomsByAccountSaga),
  takeLatest(
    S.getAuthorizedContactsByRoom.toString(),
    getAuthorizedContactsByRoomSaga,
  ),
  takeLatest(
    S.getUnauthorizedContactsByRoom.toString(),
    getUnauthorizedContactsByRoomSaga,
  ),
  takeLatest(
    S.UnauthorizeContactOnRoom.toString(),
    unauthorizeContactOnRoomSaga,
  ),
  takeLatest(S.AuthorizeContactOnRoom.toString(), authorizeContactOnRoomSaga),
  takeLatest(S.getAllContactByAccount.toString(), getAllContactByAccountSaga),
  takeLatest(S.getContactById.toString(), contactDetailsSaga),
  takeLatest(S.createContact.toString(), createContactSaga),
  takeLatest(S.updateContact.toString(), updateContactSaga),
  takeLatest(S.getRoomsByContactId.toString(), getRoomsByContactIdSaga),
];

export default staticSaga;
