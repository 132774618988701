import React from 'react';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import AppLayout from '@crema/core/AppLayout';
import AuthRoutes from '@crema/utility/AuthRoutes';
import LocaleProvider from '@crema/utility/LocaleProvider';
import CremaThemeProvider from '@crema/utility/CremaThemeProvider';
import CremaStyleProvider from '@crema/utility/CremaStyleProvider';
import ContextProvider from '@crema/utility/ContextProvider';
import reduxStore from './redux/store/index';
import {PersistGate} from 'redux-persist/integration/react';

import {history} from './redux/store';
import CssBaseline from '@material-ui/core/CssBaseline';

const {store, persistor} = reduxStore();

const App = () => (
  <ContextProvider>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <CremaThemeProvider>
          <CremaStyleProvider>
            <LocaleProvider>
              <ConnectedRouter history={history}>
                <AuthRoutes>
                  <CssBaseline />
                  <AppLayout />
                </AuthRoutes>
              </ConnectedRouter>
            </LocaleProvider>
          </CremaStyleProvider>
        </CremaThemeProvider>
      </PersistGate>
    </Provider>
  </ContextProvider>
);

export default App;
