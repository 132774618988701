import {createAction} from 'redux-smart-actions';
import * as SagaParams from '../sagas/caseSaga';
import * as O from '../../types/objects';
import * as P from '../../types/params';

/** GETTERS **/

export const getAllCases = createAction(
  'GET_ALL_CASES',
  (payload: P.UseAccountIdParams) => ({payload}),
);

export const getCaseMessages = createAction(
  'GET_CASE_MESSAGES',
  (payload: P.CaseMessagesParams) => ({
    payload,
  }),
);

export const getCaseAttachments = createAction(
  'GET_CASE_ATTACHMENTS',
  (payload: SagaParams.CaseAttachmentSagaParams) => ({payload}),
);

export const getCaseAttachmentByID = createAction(
  'GET_CASE_ATTACHMENT_BY_ID',
  (payload: SagaParams.GetCaseAttachmentByIdSagaParams) => ({
    payload,
  }),
);

/** SETTERS **/

export const setAllCases = createAction(
  'SET_ALL_CASES',
  (payload: O.CasesObject | undefined) => ({payload}),
);

export const setCaseMessages = createAction(
  'SET_CASE_MESSAGES',
  (payload: O.CaseMessagesObject) => ({payload}),
);

export const setSelectedCase = createAction(
  'SET_SELECTED_CASE',
  (payload: O.Case) => ({payload}),
);

export const createCaseMessage = createAction(
  'CREATE_CASE_MESSAGE',
  (payload: P.CreateCaseMessageParams) => ({payload}),
);

export const createCase = createAction(
  'CREATE_CASE',
  (payload: SagaParams.CreateCaseSagaParams) => ({payload}),
);

export const closeCase = createAction(
  'CLOSE_CASE',
  (payload: SagaParams.CloseCaseSagaParams) => ({payload}),
);

export const addCaseAttachment = createAction(
  'ADD_CASE_ATTACHMENT',
  (payload: SagaParams.AddCaseAttachmentSagaParams) => ({payload}),
);
