//import {combineReducers} from 'redux';

import AppReducer from './AppReducer';
import Auth from './Auth';
import CommonReducer from './CommonReducer';
import CaseReducer from './CaseReducer';
import Settings from './Setting';
import StaticReducer from './StaticReducer';
import UserReducer from './UserReducer';

const reducers = {
  auth: Auth,
  app: AppReducer,
  common: CommonReducer,
  case: CaseReducer,
  settings: Settings,
  static: StaticReducer,
  user: UserReducer,
};

export default reducers;
