import produce from 'immer';
import {logoutAction} from 'redux/actions';
import {CaseReducer} from '../../types';
import * as C from '../actions/caseActions';

export const initialState: CaseReducer = {
  cases: {Cases: [], status: ''},
  caseMessages: {CaseMessages: [], status: ''},
  selectedCase: {
    CaseId: '',
    CaseNumber: '',
    Description: '',
    Status: '',
    Subject: '',
  },
};

const caseReducer = produce((draft: CaseReducer, action: any) => {
  switch (action.type) {
    case C.setAllCases.toString():
      const cases = action.payload;
      draft.cases = {Cases: cases?.Cases, status: cases?.status};
      break;
    case C.setCaseMessages.toString():
      const caseMessages = action.payload;
      draft.caseMessages = {
        CaseMessages: caseMessages?.CaseMessages,
        status: caseMessages?.status,
      };
      break;
    case C.setSelectedCase.toString():
      draft.selectedCase = action.payload;
      break;
    case logoutAction.toString():
      draft.cases = initialState.cases;
      draft.caseMessages = initialState.caseMessages;
      draft.selectedCase = initialState.selectedCase;
      break;
  }
}, initialState);

export default caseReducer;
