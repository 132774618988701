import {useSelector} from 'react-redux';
import {roleSelector} from '../redux/selectors';

export interface NavItemProps {
  id: string;
  messageId: string;
  title: string;
  icon?: string;
  exact?: boolean;
  url?: string;
  type?: string;
  count?: number;
  color?: string;
  auth?: string[];
  children?: NavItemProps[] | NavItemProps;
}

const RoutesConfig = (): {
  id: string;
  title: string;
  messageId: string;
  type: string;
  icon: string;
  url: string;
}[] => {
  const role = useSelector(roleSelector);
  if (role === 'true') {
    return [
      {
        id: 'companyDetails',
        title: 'companyDetails',
        messageId: 'sidebar.companyDetails',
        type: 'item',
        icon: 'apartment',
        url: '/company-details',
      },
      {
        id: 'users',
        title: 'users',
        messageId: 'sidebar.users',
        type: 'item',
        icon: 'people-alt',
        url: '/users',
      },
      {
        id: 'doorAuthorization',
        title: 'doorAuthorization',
        messageId: 'sidebar.doorAuthorization',
        type: 'item',
        icon: 'meeting_room',
        url: '/door-authorization',
      },
      {
        id: 'support',
        title: 'support',
        messageId: 'sidebar.support',
        type: 'item',
        icon: 'help_outline',
        url: '/support',
      },
    ];
  } else {
    return [
      {
        id: 'companyDetails',
        title: 'companyDetails',
        messageId: 'sidebar.companyDetails',
        type: 'item',
        icon: 'dashboard',
        url: '/company-details',
      },
    ];
  }
};
/*
const routesConfig: NavItemProps[] = [
  {
    id: 'companyDetails',
    title: 'companyDetails',
    messageId: 'sidebar.companyDetails',
    type: 'item',
    icon: 'dashboard',
    url: '/company-details',
  },
  {
    id: 'users',
    title: 'users',
    messageId: 'sidebar.users',
    type: 'item',
    icon: 'dashboard',
    url: '/users',
  },
  {
    id: 'doorAuthorization',
    title: 'doorAuthorization',
    messageId: 'sidebar.doorAuthorization',
    type: 'item',
    icon: 'dashboard',
    url: '/door-authorization',
  },
];
*/
export default RoutesConfig;
