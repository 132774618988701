import {call, put, takeLatest} from '@redux-saga/core/effects';
import {
  BodyType,
  createRequestSaga,
  fetchError,
  fetchStart,
  fetchSuccess,
  loginAction,
  LoginActionObject,
  logoutAction,
  setUser,
  checkUser,
} from 'redux/actions';
import {
  SET_AUTH_TOKEN,
  SIGNOUT_AUTH_SUCCESS,
  UPDATE_AUTH_USER,
} from 'types/actions/Auth.actions';
import * as O from 'types/objects';
import {CheckUserParams} from 'types/params';
import {ApiCallback, ApiResponse, UserReducer} from '../../types';
import {verifyResponse} from '../../utils/Properties';
import {getUserObject, setJWTToken} from '../actions/JWTAuth';

export interface CheckUserSagaParams extends ApiCallback<string> {
  params: CheckUserParams;
}

function* checkUserSaga({
  payload,
}: {
  type: string;
  payload: CheckUserSagaParams;
}) {
  yield put(fetchStart());
  try {
    const response: ApiResponse<string, null> = yield call(createRequestSaga, {
      method: 'POST',
      url: 'Auth/CheckUser',
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (verifyResponse(response)) {
      let returnMessage: string = response.Result;
      if (response.Result) {
        const isError = returnMessage
          .substring(returnMessage.indexOf('status') + 9)
          .substring(0, 5);
        const errorMessage = returnMessage
          .substring(returnMessage.indexOf('errorMsg') + 11)
          .substring(0, 63);

        if (isError === 'error') {
          payload.onError(response.Message);
          yield put(fetchError(errorMessage));
        } else {
          payload.onSuccess(response.Message, response.Result);
          yield put(fetchSuccess());
        }
      }
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR checkUserSaga', e);
    yield put(fetchError(e.Message));
  }
}

type LoginActionParams = {
  type: string;
  payload: LoginActionObject;
};

function* loginSaga({payload}: LoginActionParams) {
  yield put(fetchStart());
  try {
    const response: ApiResponse<O.UserObject, string> = yield call(
      createRequestSaga,
      {
        method: 'POST',
        url: 'Auth/checkPassword',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      localStorage.setItem('mail', JSON.stringify(payload.params.mailAddress));
      yield put(
        setUser({
          user: response?.Result,
          token: response?.SubResult,
        } as UserReducer),
      );
      if (response.Result) {
        yield put({
          type: UPDATE_AUTH_USER,
          payload: getUserObject(
            response?.Result as O.UserObject,
            response.SubResult as string,
          ),
        });
        yield put({
          type: SET_AUTH_TOKEN,
          payload: setJWTToken(response.SubResult as string),
        });
      }
      yield put(fetchSuccess());
    } else {
      payload.onMessage(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR loginSaga', e);
    yield put(fetchError(e.Message));
  }
}

function* logoutSaga() {
  //yield put(fetchSuccess());
  yield put({type: SIGNOUT_AUTH_SUCCESS});
  yield put(fetchSuccess());
  localStorage.removeItem('token');
}

const userSaga = [
  takeLatest(loginAction.toString(), loginSaga),
  takeLatest(logoutAction.toString(), logoutSaga),
  takeLatest(checkUser.toString(), checkUserSaga),
];

export default userSaga;
