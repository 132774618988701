import {createAction} from 'redux-smart-actions';
import {CheckUserSagaParams} from 'redux/sagas/userSaga';
import {UserReducer} from '../../types';
import {LoginParams} from '../../types/params';

export const checkUser = createAction(
  'CHECK_USER',
  (payload: CheckUserSagaParams) => ({payload}),
);

export type LoginActionObject = {
  params: LoginParams;
  onMessage: (message: string) => void;
};
export const loginAction = createAction(
  'LOGIN_ACTION',
  (payload: LoginActionObject) => ({
    payload,
  }),
);
export const logoutAction = createAction('LOGOUT_ACTION');

export const setUser = createAction('SET_USER', (payload: UserReducer) => ({
  payload,
}));
