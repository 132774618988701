import {takeLatest, call, put} from '@redux-saga/core/effects';
import * as P from '../../types/params';
import * as O from '../../types/objects';
import * as C from '../actions/caseActions';
import {ApiCallback, ApiResponse} from '../../types/index';
import {verifyResponse} from '../../utils/Properties';
import {
  BodyType,
  createRequestSaga,
  fetchError,
  fetchStart,
  fetchSuccess,
  showMessage,
} from 'redux/actions';

function* getAllCasesSaga({
  payload,
}: {
  type: string;
  payload: P.UseAccountIdParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.CasesObject, null> = yield call(
      createRequestSaga,
      {
        method: 'GET',
        url: 'Case/GetAllCases',
        body: payload,
      },
    );
    if (verifyResponse(response)) {
      yield put(C.setAllCases(response.Result));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

function* getCaseMessagesSaga({
  payload,
}: {
  type: string;
  payload: P.CaseMessagesParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.CaseMessagesObject, null> = yield call(
      createRequestSaga,
      {
        method: 'GET',
        url: 'Case/GetCaseMessages',
        body: payload,
      },
    );
    if (verifyResponse(response)) {
      yield put(C.setCaseMessages(response.Result));
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR getCaseMessagesSaga', e);
    yield put(fetchError(e.Message));
  }
}

function* createCaseMessageSaga({
  payload,
}: {
  type: string;
  payload: P.CreateCaseMessageParams;
}) {
  const body = {CaseID: payload.CaseID, Message: payload.Message};
  try {
    yield put(fetchStart());
    const response: ApiResponse<string, null> = yield call(createRequestSaga, {
      method: 'POST',
      url: 'Case/CreateCaseMessages',
      body: body,
      bodyType: BodyType.formdata,
    });
    if (verifyResponse(response)) {
      yield put(
        C.getCaseMessages({
          AccountID: payload.AccountID,
          CaseID: payload.CaseID,
        }),
      );
      yield put(fetchSuccess());
    } else {
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR createCaseMessageSaga', e);
    yield put(fetchError(e.Message));
  }
}

export interface CreateCaseSagaParams extends ApiCallback<string> {
  params: P.CreateCaseParams;
}

function* createCaseSaga({
  payload,
}: {
  type: string;
  payload: CreateCaseSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<string, null> = yield call(createRequestSaga, {
      method: 'POST',
      url: 'Case/CreateCase',
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(showMessage(response.Message));
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

export interface CloseCaseSagaParams extends ApiCallback<string> {
  params: P.CaseMessagesParams;
}

function* closeCaseSaga({
  payload,
}: {
  type: string;
  payload: CloseCaseSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<string, null> = yield call(createRequestSaga, {
      method: 'POST',
      url: 'Case/CloseCase',
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(showMessage(response.Message));
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR closeCaseSaga', e);
    yield put(fetchError(e.Message));
  }
}

export interface CaseAttachmentSagaParams
  extends ApiCallback<O.CaseAttachmentObject[]> {
  params: P.CaseAttachmentParams;
}

function* getCaseAttachmentsSaga({
  payload,
}: {
  type: string;
  payload: CaseAttachmentSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<O.CaseAttachmentObject[], null> = yield call(
      createRequestSaga,
      {
        method: 'GET',
        url: 'Case/getCaseAttachments',
        body: payload.params,
        bodyType: BodyType.formdata,
      },
    );
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(fetchSuccess());
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR UnauthorizeContactOnRoom', e);
    yield put(fetchError(e.Message));
  }
}

export interface GetCaseAttachmentByIdSagaParams extends ApiCallback<string> {
  params: P.GetCaseAttachmentByIdParams;
}

function* getCaseAttachmentByIdSaga({
  payload,
}: {
  type: string;
  payload: GetCaseAttachmentByIdSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<string, null> = yield call(createRequestSaga, {
      method: 'GET',
      url: 'Case/getCaseAttachmentByID',
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(fetchSuccess());
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR getCaseAttachmentByIdSaga', e);
    yield put(fetchError(e.Message));
  }
}

export interface AddCaseAttachmentSagaParams extends ApiCallback<string> {
  params: P.AddCaseAttachemtParams;
}

function* addCaseAttachmentSaga({
  payload,
}: {
  type: string;
  payload: AddCaseAttachmentSagaParams;
}) {
  try {
    yield put(fetchStart());
    const response: ApiResponse<string, null> = yield call(createRequestSaga, {
      method: 'POST',
      url: 'Case/AddAttachmentToCase',
      body: payload.params,
      bodyType: BodyType.formdata,
    });
    if (verifyResponse(response)) {
      payload.onSuccess(response.Message, response.Result);
      yield put(showMessage(response.Message));
    } else {
      payload.onError(response.Message);
      yield put(fetchError(response.Message));
    }
  } catch (e) {
    console.log('ERROR addCaseAttachmentSaga', e);
    yield put(fetchError(e.Message));
  }
}

const caseSaga = [
  takeLatest(C.getAllCases.toString(), getAllCasesSaga),
  takeLatest(C.getCaseMessages.toString(), getCaseMessagesSaga),
  takeLatest(C.getCaseAttachments.toString(), getCaseAttachmentsSaga),
  takeLatest(C.createCaseMessage.toString(), createCaseMessageSaga),
  takeLatest(C.createCase.toString(), createCaseSaga),
  takeLatest(C.closeCase.toString(), closeCaseSaga),
  takeLatest(C.addCaseAttachment.toString(), addCaseAttachmentSaga),
  takeLatest(C.getCaseAttachmentByID.toString(), getCaseAttachmentByIdSaga),
];

export default caseSaga;
