import {AppContext} from '@crema';
//import {useContext} from 'react';
import {createSelector} from 'reselect';
//import AppContextPropsType from 'types/AppContextPropsType';
import {RootState} from '../../types';

/** USER AND COMPANIES **/

const getState = (state: RootState) => state;
export const getApiParams = createSelector([getState], (state) => {
  //@ts-ignore
  const {locale} = AppContext._currentValue;
  return {
    lang: locale,
    token: state.user?.token || '',
  };
});

const userState = (state: RootState) => state.user.user;
export const userSelector = createSelector([userState], (user) => user);

export const isUserLoggedSelector = createSelector(
  [userState],
  (user) => user !== undefined,
);

const userCompaniesState = (state: RootState) => state.user.user?.Accounts;
export const userCompaniesSelector = createSelector(
  [userCompaniesState],
  (companies) => companies,
);

const companyState = (state: RootState) => state.app.selectedCompany;
export const companySelector = createSelector(
  [companyState],
  (company) => company,
);

const selectedCompanyIdState = (state: RootState) =>
  state.app.selectedCompany?.AccountId;
export const selectedAccountIdSelector = createSelector(
  [selectedCompanyIdState],
  (companyId) => companyId,
);

const roleState = (state: RootState) => state.app.selectedCompany.IsAdmin;
export const roleSelector = createSelector([roleState], (company) => company);

/** DOORS **/
const roomsState = (state: RootState) => state.static.rooms?.Products;
export const roomsSelector = createSelector([roomsState], (rooms) => rooms);

/** USERS **/
const usersState = (state: RootState) => state.static.contacts?.Contacts;
export const usersSelector = createSelector([usersState], (users) => users);

/** CASES **/
const casesState = (state: RootState) => state.case.cases?.Cases;
export const casesSelector = createSelector([casesState], (cases) => cases);

const caseMessagesState = (state: RootState) =>
  state.case.caseMessages?.CaseMessages;
export const caseMessagesSelector = createSelector(
  [caseMessagesState],
  (caseMessages) => caseMessages,
);

const selectedCaseState = (state: RootState) => state.case.selectedCase;
export const selectedCaseSelector = createSelector(
  [selectedCaseState],
  (selectedCase) => selectedCase,
);
