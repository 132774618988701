import React, {useContext, useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {useHistory} from 'react-router-dom';
import Avatar from '@material-ui/core/Avatar';
import {logoutAction} from '../../../redux/actions';
import {useAuthUser} from '../../../@crema/utility/AppHooks';
import AppContext from '../../../@crema/utility/AppContext';
import clsx from 'clsx';
import {makeStyles, Tooltip} from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Box from '@material-ui/core/Box';
import {orange} from '@material-ui/core/colors';
import {Fonts, ThemeMode} from '../../constants/AppEnums';
import Hidden from '@material-ui/core/Hidden';
import AppContextPropsType, {
  CremaTheme,
} from '../../../types/AppContextPropsType';
import {AuthUser} from '../../../types/models/AuthUser';
import {userCompaniesSelector, companySelector} from '../../../redux/selectors';
import {companySelect} from 'redux/actions/AppActions';

const useStyles = makeStyles((theme: CremaTheme) => {
  return {
    crHeaderUser: {
      backgroundColor: (props: {header: boolean; themeMode: ThemeMode}) =>
        props.header ? 'transparent' : 'rgba(0,0,0,.08)',
      paddingTop: 9,
      paddingBottom: 9,
      minHeight: 56,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
        minHeight: 70,
      },
    },
    profilePic: {
      fontSize: 24,
      backgroundColor: orange[500],
    },
    userInfo: {
      width: (props: {header: boolean; themeMode: ThemeMode}) =>
        !props.header ? 'calc(100% - 75px)' : '100%',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      fontSize: 15,
      fontWeight: Fonts.MEDIUM,
      color: (props: {header: boolean; themeMode: ThemeMode}) =>
        props.themeMode === ThemeMode.DARK || !props.header
          ? 'white'
          : '#313541',
    },
    pointer: {
      cursor: 'pointer',
    },
  };
});

interface HeaderUserProps {
  header?: boolean;
}

const HeaderUser: React.FC<HeaderUserProps> = ({header = true}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const companies = useSelector(userCompaniesSelector);
  const company = useSelector(companySelector);
  const {themeMode} = useContext<AppContextPropsType>(AppContext);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const user: AuthUser | null = useAuthUser();

  useEffect(() => {
    if (companies && companies.length > 0)
      dispatch(companySelect(companies[0]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getUserAvatar = () => {
    if (user && user.displayName) {
      return user.displayName.charAt(0).toUpperCase();
    }
    if (user && user.email) {
      return user.email.charAt(0).toUpperCase();
    }
  };
  const getUserAvatarView = () => {
    if (user && user.photoURL) {
      return <Avatar className={classes.profilePic} src={user.photoURL} />;
    } else {
      return <Avatar className={classes.profilePic}>{getUserAvatar()}</Avatar>;
    }
  };

  const classes = useStyles({themeMode, header});

  const companyName =
    company.AccountName?.length > 25
      ? company.AccountName.slice(0, 25) + ' ...'
      : company.AccountName;

  return (
    <Box
      px={{xs: 2, xl: 6}}
      className={clsx(classes.crHeaderUser, 'cr-user-info')}>
      <Box display='flex' alignItems='center'>
        <Hidden mdDown> {getUserAvatarView()}</Hidden>
        <Box className={clsx(classes.userInfo, 'user-info')}>
          <Box
            display='flex'
            alignItems='center'
            justifyContent='space-between'>
            <Box onClick={handleClick}>
              <Hidden mdDown>
                <Box ml={4} mb={1.5} className={classes.userName}>
                  {user && (user.displayName ? user.displayName : user.email)}
                  <Tooltip
                    enterDelay={500}
                    title={
                      company?.AccountName?.length > 20
                        ? company.AccountName
                        : ''
                    }>
                    <Box
                      fontSize={13}
                      fontWeight={Fonts.LIGHT}
                      color='text.secondary'>
                      {companyName}
                    </Box>
                  </Tooltip>
                </Box>
              </Hidden>
            </Box>
            <Box
              ml={{md: 3}}
              className={classes.pointer}
              color={
                themeMode === ThemeMode.DARK || !header ? 'white' : '#313541'
              }>
              <Hidden mdDown>
                <ExpandMoreIcon onClick={handleClick} />
              </Hidden>
              <Hidden lgUp>
                <Box component='span' onClick={handleClick}>
                  {getUserAvatarView()}
                </Box>
              </Hidden>
              <Menu
                id='simple-menu'
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}>
                <MenuItem onClick={() => setMenuOpen(!menuOpen)}>
                  {companyName}
                  <ExpandMoreIcon />
                </MenuItem>
                <Box
                  style={{
                    width: '100%',
                    height: menuOpen ? 90 : 0,
                    transition: '0.5s',
                    overflow: 'hidden',
                  }}>
                  {company &&
                    companies?.map((company, index: number) => (
                      //@ts-ignore
                      <MenuItem
                        style={{fontSize: 12}}
                        key={index}
                        onClick={() => {
                          dispatch(companySelect(company));
                          history.push('/company-details');
                          handleClose();
                          setMenuOpen(false);
                        }}>
                        {company.AccountName?.length > 35
                          ? company.AccountName.slice(0, 35) + ' ...'
                          : company.AccountName}
                      </MenuItem>
                    ))}
                </Box>
                <MenuItem>My account</MenuItem>
                <MenuItem
                  onClick={() => {
                    dispatch(logoutAction());
                  }}>
                  Logout
                </MenuItem>
              </Menu>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default HeaderUser;
HeaderUser.defaultProps = {
  header: true,
};
