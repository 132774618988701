import {produce} from 'immer';
import {companySelect} from 'redux/actions/AppActions';
import {AppReducer} from '../../types';

const initialState: AppReducer = {
  selectedCompany: {AccountId: '', AccountName: '', IsAdmin: ''},
};
const appReducer = produce((draft: AppReducer, action: any) => {
  switch (action.type) {
    case companySelect.toString():
      draft.selectedCompany = action.payload;
      break;
  }
}, initialState);

export default appReducer;
