import {createAction} from 'redux-smart-actions';
import * as SagaParams from '../sagas/staticSaga';
import * as O from '../../types/objects';
import * as P from '../../types/params';

/** GETTERS **/

/* Rooms */
export const getRoomsByAccount = createAction(
  'GET_ROOMS_BY_ACCOUNT',
  (payload: P.UseAccountIdParams) => ({payload}),
);

export const getAuthorizedContactsByRoom = createAction(
  'GET_AUTHORIZED_CONTACTS_BY_ROOM',
  (payload: SagaParams.GetAuthorizedContactsByRoomSagaParams) => ({payload}),
);

export const getUnauthorizedContactsByRoom = createAction(
  'GET_UNAUTHORIZED_CONTACTS_BY_ROOM',
  (payload: SagaParams.GetAuthorizedContactsByRoomSagaParams) => ({payload}),
);

/* Users */
export const getAllContactByAccount = createAction(
  'GET_ALL_CONTACT_BY_ACCOUNT',
  (payload: P.UseAccountIdParams) => ({payload}),
);

export const getContactById = createAction(
  'GET_CONTACT_BY_ID',
  (payload: SagaParams.GetContactDetailsSagaParams) => ({
    payload,
  }),
);

export const getRoomsByContactId = createAction(
  'GET_ROOMS_BY_CONTACT_ID',
  (payload: SagaParams.GetRoomsByContactIdSagaParams) => ({
    payload,
  }),
);

/** SETTERS **/

/* Rooms */
export const setRoomsByAccount = createAction(
  'SET_ROOMS_BY_ACCOUNT',
  (payload: O.RoomsObject) => ({payload}),
);

export const UnauthorizeContactOnRoom = createAction(
  'UNAUTHORIZE_CONTACT_ON_ROOM',
  (payload: SagaParams.AuthorizeContactOnRoomSagaParams) => ({payload}),
);

export const AuthorizeContactOnRoom = createAction(
  'AUTHORIZE_CONTACT_ON_ROOM',
  (payload: SagaParams.AuthorizeContactOnRoomSagaParams) => ({payload}),
);

/* Users */
export const setAllContactByAccount = createAction(
  'SET_ALL_CONTACT_BY_ACCOUNT',
  (payload: O.ContactsObject | undefined) => ({payload}),
);

export const createContact = createAction(
  'CREATE_CONTACT',
  (payload: SagaParams.CreateContactSagaParams) => ({
    payload,
  }),
);

export const updateContact = createAction(
  'UPDATE_CONTACT',
  (payload: SagaParams.CreateContactSagaParams) => ({
    payload,
  }),
);
