import React from 'react';

export const samplePagesConfig = [
  {
    auth: ['user'],
    routes: [
      {
        path: '/company-details',
        component: React.lazy(() => import('./Pages/CompanyDetails')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/users',
        component: React.lazy(() => import('./Pages/Users')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/door-authorization',
        component: React.lazy(() => import('./Pages/DoorAuthorization')),
      },
    ],
  },
  {
    auth: ['user'],
    routes: [
      {
        path: '/support',
        component: React.lazy(() => import('./Pages/Cases')),
      },
    ],
  },
];
